import { Aside } from "./Components/Aside";
import { Header } from "./Components/Header";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import { States } from "./Pages/States";
import { Scan } from "./Pages/Scan";
import { CurrentTheme } from "./Utils/Constants";
import { Home } from "./Survey/Home";

const SurveyApp = () => {
  const themeStyles = {
    "--primary": CurrentTheme.primary,
    "--secondary": CurrentTheme.secondary,
  } as any;

  return (
    <Router>
      <div
        className={"App hideScreen LightTheme " + CurrentTheme.class}
        style={themeStyles}
      >
        <div className="Authenticated">
          {/* <Aside /> */}
          <div className="AppRootContent">
            {/* <Header /> */}
            <Routes>
              <Route path="/" element={<Home />} />
              {/* <Route path="/states" element={<States />} />
              <Route path="/scan" element={<Scan />} /> */}
              {/*<Route path="/signup" component={Signup} /> */}
            </Routes>
          </div>
        </div>
      </div>

      <div id="ModalComponent">
        <div id="LayerBlack"></div>
        <div id="ModalContent"></div>
      </div>
    </Router>
  );
};

export default SurveyApp;
