export const API = "https://api-socialai.warriorsguys.com/?";
export const cloudFunction = "cloudFunction=";
export const postFilter = "_POST";
export const getFilter = "_GET";
export const filter = "&filter=";
export const hook = "&afterPost=";

export const theme = [
  // {
  //   idGlobal: 1,
  //   candidate: "Claudia Sheinboum",
  //   primary: "#97005c",
  //   secondary: "#7d004c",
  //   class: "ClaudiaSheinboum",
  //   hue: "",
  //   image: "claudia.png",
  // },

  // {
  //   idGlobal: 2,
  //   candidate: "Xiochitl Galvez",
  //   primary: "#e240c7",
  //   secondary: "#9e0d85",
  //   class: "XiochitlGalvez",
  //   hue: "",
  //   image: "xiochitl.png",
  // },

  {
    idGlobal: 3,
    candidate: "Gerardo Vargas",
    primary: "#97005c",
    secondary: "#7d004c",
    class: "GerardoVargas",
    hue: "",
    image: "gvl.png",
  },
  // {
  //   idGlobal: 4,
  //   candidate: "Mingo Vazquez",
  //   primary: "#af0eff",
  //   secondary: "#420361",
  //   class: "MingoVazques",
  //   hue: "",
  //   image: "mingo.png",
  // },
];

export let CurrentTheme = theme[0];
