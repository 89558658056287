import { useEffect, useState } from "react";
import axios from "axios";
import { API, getFilter, filter } from "../Utils/Constants";
import SurveyWidgetPolarity from "./SurveyWidgetPolarity";
import $ from "jquery";
export const Home = () => {
  const [data, setData] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);

  let DB = {} as any;
  const getData = async () => {
    try {
      const _filter = {
        table: "votos_encuesta_llamada",
      };
      const response = await axios.get(
        API + getFilter + filter + encodeURIComponent(JSON.stringify(_filter))
      );

      DB.data = response.data;
      DB.all = DB.data.length;
      DB.one = DB.data.filter(
        (obj: { vote: string }) => obj.vote === '["1"]'
      ).length;
      DB.two = DB.data.filter(
        (obj: { vote: string }) => obj.vote === '["2"]'
      ).length;
      DB.nulo = DB.data.filter(
        (obj: { vote: string }) => obj.vote === "[]"
      ).length;

      setData(DB);
      setIsLoading(false);
    } catch (error) {
      console.error("Error al obtener las publicaciones", error);
    }
  };

  useEffect(() => {
    getData();

    // const intervalId = setInterval(() => {
    //   getData();
    // }, 10000);
    if (prompt("Enter your password") === "GVLTrebol*") {
      $(".hideScreen").removeClass("hideScreen");
    }
  }, []);

  return (
    <div className="AppContent">
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <div>
          <header
            className="repotHeader"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img src="assets/images/gvl-ad.png" className="imgCandidateLogo" />
          </header>
          <h2 className="subtitleReport">Encuesta Telefonica</h2>
          <br />

          {/* <WidgetWords db={data} />
            <WidgetNegativeComments db={data} /> */}

          {/*<WidgetCalendar />*/}
          {/* <WidgetPolarityVs info={data} /> */}

          <div className="HomeDashboard">
            <div className="titleNetwork llamadas">
              <div className="img"></div>
              Llamadas
            </div>
            <SurveyWidgetPolarity db={data} />
          </div>
        </div>
      )}
    </div>
  );
};
