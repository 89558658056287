/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
const SurveyWidgetPolarity = ({ db }: any) => {
  const maxValue = db.all;
  const oneValue = db.one;
  const twoValue = db.two;
  const nuloValue = db.nulo;

  const oneHeight = (oneValue / maxValue) * 100;
  const twoHeight = (twoValue / maxValue) * 100;
  const nuloHeight = (nuloValue / maxValue) * 100;

  return (
    <div className="Card WidgetPolarity Shadow PhoneWidget">
      {/* <Doughnut data={data} options={options} /> */}
      <div className="Validity">
        <div className="TitleWidget">Respuestas ( {db.all} )</div>
        <div className="WidgetPolarityContent">
          <div className="Bar Negativos">
            <div
              className="ProgressBar"
              style={{
                height: `calc(${oneHeight}% )`,
              }}
            ></div>
            <div className="Label">
              {db.one} <br /> ( #1 )
            </div>
          </div>

          <div className="Bar Neutrales">
            <div
              className="ProgressBar"
              style={{
                height: `calc(${twoHeight}% )`,
              }}
            ></div>
            <div className="Label">
              {db.two} <br /> ( #2 )
            </div>
          </div>

          <div className="Bar Positivos">
            <div
              className="ProgressBar"
              style={{
                height: `calc(${nuloHeight}% )`,
              }}
            ></div>
            <div className="Label">
              {db.nulo} <br /> ( NULO )
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurveyWidgetPolarity;
